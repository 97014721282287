<template>
  <v-container>
    <v-row class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar" id="toolbar-title">
              {{ $refs.calendar.title | capitalize }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialogCJMultiple" max-width="418px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <Button
                  v-bind="attrs"
                  v-on="on"
                  class="mb-2 pr-0 pl-0 mr-2"
                  action_name="Congé/Jour-férié (en lot)"
                  color="primary"
                  text
                  @clicked="openAddDialog(true)"
                />
              </template>
              <v-card>
                <v-card-title class="justify-space-between">
                  <span class="headline">{{
                    "Congé/Jour-férié (en lot)"
                  }}</span>
                  <v-btn ref="close" color="black" icon right @click="close">
                    <v-icon color="#B2BBAB">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-form ref="form" style="width: 100%" v-model="isValid">
                        <v-col class="pa-0" cols="12" md="12" sm="6">
                          <AutoComplete
                            id="dossier"
                            label="Projet*"
                            v-model="editedItem.dossier"
                            :items="dossiers"
                            item-text="name"
                            item-value="id"
                            :rules="requiredRules"
                            ref="dossier"
                            @change="handleDossierSelect"
                            return-object
                          />
                        </v-col>
                        <v-col class="pa-0" cols="12" md="12" sm="6">
                          <AutoComplete
                            id="operation"
                            label="Type d'opération*"
                            v-model="editedItem.operation"
                            :items="operations"
                            item-text="name"
                            item-value="id"
                            :rules="requiredRules"
                          />
                        </v-col>
                        <v-col class="pa-0" cols="12" md="12" sm="6">
                          <AutoComplete
                            id="days"
                            v-model="editedItem.days"
                            label="Jours*"
                            :items="days"
                            :rules="requiredRules"
                            multiple
                            deletable-chips
                            small-chips
                          />
                        </v-col>
                        <v-col class="pa-0" cols="12" md="12" sm="6">
                          <TextArea
                            id="details"
                            label="Détails"
                            v-model="editedItem.details"
                          />
                        </v-col>
                      </v-form>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="pb-6 btn-actions">
                  <Button
                    action_name="Continuer"
                    color="#86CC26"
                    @clicked="save(true)"
                    :loading="loadingContinue"
                  />
                  <Button
                    action_name="Ajouter"
                    color="#11887E"
                    text
                    @clicked="save(false)"
                    :loading="loadingAdd"
                  />
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPMMultiple" max-width="418px" persistent>
              <template v-slot:activator="{ on, attrs }">
                <Button
                  v-bind="attrs"
                  v-on="on"
                  class="mb-2 pr-0 pl-0"
                  action_name="Prolongation/Modification (en lot)"
                  color="primary"
                  text
                  @clicked="openAddPMDialog(true)"
                />
              </template>
              <v-card>
                <v-card-title class="justify-space-between">
                  <span class="headline">{{
                    "Prolongation/Modification (en lot)"
                  }}</span>
                  <v-btn ref="close" color="black" icon right @click="close">
                    <v-icon color="#B2BBAB">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-form ref="form" style="width: 100%" v-model="isValid">
                        <v-col class="pa-0" cols="12" md="12" sm="6">
                          <AutoComplete
                            id="type"
                            label="Type de demande*"
                            v-model="PMeditedItem.type"
                            :items="types"
                            item-text="name"
                            item-value="id"
                            :rules="requiredRules"
                            ref="type"
                          />
                        </v-col>
                        <v-col class="pa-0" cols="12" md="12" sm="6">
                          <AutoComplete
                            id="month"
                            label="Mois*"
                            v-model="PMeditedItem.month"
                            :items="months"
                            item-text="name"
                            item-value="id"
                            :rules="requiredRules"
                          />
                        </v-col>
                        <v-col class="pa-0" cols="12" md="12" sm="6">
                          <AutoComplete
                            id="year"
                            label="Année*"
                            v-model="PMeditedItem.year"
                            :items="years"
                            item-text="name"
                            item-value="id"
                            :rules="requiredRules"
                          />
                        </v-col>
                        <v-col class="pa-0" cols="12" md="12" sm="6">
                          <AutoComplete
                            id="dates"
                            v-model="PMeditedItem.dates"
                            label="Jours*"
                            :items="dates"
                            :rules="requiredRules"
                            multiple
                            deletable-chips
                            small-chips
                          />
                        </v-col>
                        <v-col class="pa-0" cols="12" md="12" sm="6">
                          <text-input
                            id="raison"
                            v-model="PMeditedItem.raison"
                            label="Raison"
                          />
                        </v-col>
                      </v-form>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="pb-6 btn-actions">
                  <Button
                    action_name="Continuer"
                    color="#86CC26"
                    @clicked="savePM(true)"
                    :loading="loadingPMContinue"
                  />
                  <Button
                    action_name="Ajouter"
                    color="#11887E"
                    text
                    @clicked="savePM(false)"
                    :loading="loadingPMAdd"
                  />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </v-sheet>

        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            type="month"
            :weekdays="weekday"
            @click:day="dayClick"
            :weekday-format="myDayFormat"
            locale="fr"
            :month-format="MyMonthFormat"
            event-name="name"
          >
            <template v-slot:event="{ event }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="event-block" v-bind="attrs" v-on="on">
                    <p
                      class="event-name"
                      :style="{ background: event.color }"
                    ></p>
                    <p class="event-hours">
                      Total : {{ event.name | moment_time }} H
                    </p>
                  </div>
                </template>
                <span>{{
                  eventsColors[
                    eventsColors.findIndex((el) => el.color === event.color)
                  ].name
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:day-label="{ date }">
              <div class="wrapper">
                <span class="day-name">{{ date | moment("dddd") }} </span><br />
                <span class="day-date">{{ date | moment("DD/MM/YYYY") }}</span>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
      <v-dialog v-model="dialog" max-width="600px" persistent>
        <v-card>
          <v-card-title class="justify-space-between">
            <span class="headline">Demande de Prolongation</span>
            <v-btn color="black" dark icon right @click="close">
              <v-icon color="#B2BBAB">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="isValid"
              @keyup.native.enter="requestProlongation"
            >
              <text-input
                id="raison"
                v-model="requestItem.raison"
                label="Raison *"
                :rules="requiredRules"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="pb-3 pt-0">
            <v-spacer></v-spacer>
            <Button
              :disabled="!isValid"
              action_name="Demander"
              color="secondary"
              @clicked="requestProlongation"
              :loading="loadingProlongation"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import { getTimeSheetList, checkTimeSheet } from "@/services/timesheet.api";
import { addProlongation } from "@/services/prolongation.api";
import { Button, TextInput, AutoComplete, TextArea } from "@/components/base";
import { getOperationsByDossierName } from "../../../services/operations.api";
import {
  addCongeTimeSheetLines,
  addMultipleProlongations,
  getTimesheetDatesBasedOnParams,
  getTimesheetStartDatesForUser,
  getYears,
} from "../../../services/timesheet.api";
import months from "@/assets/data/months.json";
export default {
  components: { Button, TextInput, AutoComplete, TextArea },
  methods: {
    requestProlongation() {
      this.loadingProlongation = true;
      this.requestItem.type = 1;
      addProlongation(this.requestItem).then((response) => {
        this.loadingProlongation = false;
        this.$refs.form.reset();
        this.dialog = false;
        this.$swal.fire({
          title: "Votre demande de prolongation a été enregistré",
        });
        const index = this.events.findIndex((p) => p.start == response.data);
        this.events[index].color = "purple";
      });
    },
    close() {
      this.dialog = false;
      this.dialogCJMultiple = false;
      this.dialogPMMultiple = false;
    },
    getAllTimeSheetList(endDate) {
      getTimeSheetList(endDate).then((response) => {
        this.apidata = response.data;
        this.events = [];
        for (let j = 0; j < this.apidata.length; j++) {
          this.newapidata = {};
          this.newapidata.name = "" + this.apidata[j].name;
          this.newapidata.start = this.apidata[j].start_date;
          this.newapidata.id = this.apidata[j].id;
          this.newapidata.end = this.apidata[j].start_date;
          if (this.apidata[j].is_ignored === 1) {
            this.newapidata.color = "blue";
          } else if (this.apidata[j].is_holiday === 1) {
            this.newapidata.color = "orange";
          } else if (this.apidata[j].is_expired === 1) {
            this.newapidata.color = "red";
          } else if (this.apidata[j].name >= 8) {
            this.newapidata.color = "green";
          } else if (this.apidata[j].is_requested === 1) {
            this.newapidata.color = "purple";
          } else {
            this.newapidata.color = "grey";
          }
          this.newapidata.timed = false;
          this.events.push(this.newapidata);
        }
      });
    },
    MyMonthFormat() {
      return "test";
    },
    myDayFormat() {
      return "";
    },
    dayClick({ date }) {
      if (!this.isFetching) {
        this.isFetching = true;
        checkTimeSheet(date).then((response) => {
          this.isFetching = false;
          if (response.data === "exist") {
            this.$router.push({
              path: "/dashboard/timesheet-detail",
              query: { date },
            });
          } else if (
            response.data.message ===
              "Une demande a ete déja faite pour ce rapport !" ||
            response.data.message === "Aucun rapport disponible pour ce jour !"
          ) {
            this.$swal.fire({
              icon: response.data.icon,
              title: response.data.message,
            });
          } else {
            this.requestItem.date = date;
            this.dialog = true;
          }
        });
      }
    },
    getEventColor() {
      return "transparent";
    },
    prev() {
      this.$refs.calendar.prev();
      const endDate = this.focus;
      this.getAllTimeSheetList(endDate);
    },
    next() {
      this.$refs.calendar.next();
      const endDate = this.focus;
      this.getAllTimeSheetList(endDate);
    },
    openAddDialog(dialogStat) {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      if (dialogStat) {
        this.dialogCJMultiple = dialogStat;
      }
      getTimesheetStartDatesForUser().then((response) => {
        this.days = response.data;
      });
    },
    openAddPMDialog(dialogStat) {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      if (dialogStat) {
        this.dialogPMMultiple = dialogStat;
      }
      getYears().then((response) => {
        this.years = response.data.years;
      });
      this.fetchTimesheetDatesIfNeeded();
    },
    fetchTimesheetDatesIfNeeded() {
      if (
        this.PMeditedItem.type &&
        this.PMeditedItem.month &&
        this.PMeditedItem.year
      ) {
        getTimesheetDatesBasedOnParams(this.PMeditedItem).then((response) => {
          this.dates = response.data;
        });
      }
    },
    save(dialogStat) {
      const endDate = this.$refs.calendar.lastEnd.date;
      if (!this.isValid) {
        this.$refs.form.validate();
        return;
      }
      dialogStat ? (this.loadingContinue = true) : (this.loadingAdd = true);
      addCongeTimeSheetLines(this.editedItem).then(() => {
        dialogStat ? (this.loadingContinue = false) : (this.loadingAdd = false);
        this.getAllTimeSheetList(endDate);
        this.close();
        this.openAddDialog(dialogStat);
        this.$swal.fire({ title: "Lignes ajoutées avec succès" });
      });
    },
    savePM(dialogStat) {
      const endDate = this.$refs.calendar.lastEnd.date;
      if (!this.isValid) {
        this.$refs.form.validate();
        return;
      }
      dialogStat ? (this.loadingPMContinue = true) : (this.loadingPMAdd = true);
      addMultipleProlongations(this.PMeditedItem).then(() => {
        dialogStat
          ? (this.loadingPMContinue = false)
          : (this.loadingPMAdd = false);
        this.getAllTimeSheetList(endDate);
        this.close();
        this.openAddPMDialog(dialogStat);
        this.$swal.fire({ title: "Demande ajoutée avec succès" });
      });
    },
    handleDossierSelect() {
      getOperationsByDossierName(this.editedItem.dossier.name).then(
        (response) => {
          this.operations = response.data;
        }
      );
    },
  },
  data() {
    return {
      loadingProlongation: false,
      eventsColors: [
        {
          color: "red",
          name: "Expiré",
        },
        {
          color: "purple",
          name: "Demandé",
        },
        {
          color: "green",
          name: "Rempli",
        },
        {
          color: "blue",
          name: "Ignoré",
        },
        {
          color: "orange",
          name: "Congé",
        },
        {
          color: "grey",
          name: "Non remplis",
        },
      ],
      isFetching: false,
      apidata: [],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      focus: "",
      events: [],
      dialog: false,
      timesheetDate: null,
      requestItem: {},
      isValid: false,
      dialogCJMultiple: false,
      dialogPMMultiple: false,
      editedItem: {},
      dossiers: [
        { id: 331, name: "Congé-Piximind" },
        { id: 335, name: "Jour Férié-Piximind" },
      ],
      operations: [],
      days: [],
      loadingAdd: false,
      loadingContinue: false,
      loadingPMAdd: false,
      loadingPMContinue: false,
      PMeditedItem: {},
      types: [
        { id: 1, name: "Prolongation" },
        { id: 2, name: "Modification" },
      ],
      years: [],
      months: months,
      dates: [],
    };
  },
  mounted() {
    const endDate = this.$refs.calendar.lastEnd.date;
    this.getAllTimeSheetList(endDate);
    this.$refs.calendar.checkChange();
  },
  watch: {
    "PMeditedItem.type": "fetchTimesheetDatesIfNeeded",
    "PMeditedItem.month": "fetchTimesheetDatesIfNeeded",
    "PMeditedItem.year": "fetchTimesheetDatesIfNeeded",
    dialogCJMultiple(val) {
      val || this.close();
    },
    dialogPMMultiple(val) {
      val || this.closeDelete();
    },
  },
};
</script>
<style scoped>
.month {
  font-family: "Segoe UI", serif;
  font-size: 33px;
  color: #003d42;
}

.cols-aligned {
  align-self: flex-end;
}

.theme--light.v-calendar-weekly {
  cursor: pointer;
}

.v-calendar .v-event {
  background: red !important;
}

.v-calendar-weekly__day {
  height: 100px;
}

.event-name {
  border-radius: 50%;
  height: 15px;
  width: 15px;
}

.event-block {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.wrapper {
  text-align: left;
  margin-left: 15px;
}

.day-name {
  font-family: "Segoe UI", serif;
  font-size: 24px;
  color: #003d42;
}

.day-date {
  font-family: "Segoe UI", serif;
  font-size: 20px;
  color: #003d42;
}

.event-hours {
  color: grey;
  margin-left: 15px;
}
</style>
